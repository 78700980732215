/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'
/* Theme variables */
import './theme/variables.css'

import { IonApp, IonRouterOutlet } from '@ionic/react'
import { Redirect, Route } from 'react-router-dom'

import Home from './pages/Home'
import { IonReactRouter } from '@ionic/react-router'
import Login from './pages/Login'
import React from 'react'

const App = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route path='/login' component={Login} exact={true} />
        <Route path='/home' component={Home} exact={true} />
        <Redirect exact from='/' to='/login' />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
)

export default App
