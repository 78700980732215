import firebase from 'firebase'

const conf = {
  apiKey: 'AIzaSyDQsVFEYmnKZQkcAVeb7C0O_bIx_9EJQZU',
  authDomain: 'inntro-poc.firebaseapp.com',
  databaseURL: 'https://inntro-poc.firebaseio.com',
  projectId: 'inntro-poc',
  storageBucket: 'inntro-poc.appspot.com',
  messagingSenderId: '581167358145',
  appId: '1:581167358145:web:db23183f24ded3d8c2b440',
  measurementId: 'G-0X0EVR8BV3'
}

firebase.initializeApp(conf)

export default firebase
