import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'

import React from 'react'
import firebase from '../utils/firebase'

const Home = ({ history }) => {
  const loginWithFacebook = () => {
    console.log('logging in?')
    const aFunc = () =>
      this.login(['email']).then(res => {
        const cred = firebase.auth.FacebookAuthProvider.credential(
          res.authResponse.accessToken
        )

        firebase
          .auth()
          .signInWithCredential(cred)
          .then(user => console.log(user))
      })
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Home Screen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        Lorem Ipsum
        <IonButton onClick={() => history.push('/login')} expand='block'>
          Go to login...
        </IonButton>
      </IonContent>
    </IonPage>
  )
}

export default Home
