import {
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from '@ionic/react'
import { Plugins, registerWebPlugin } from '@capacitor/core'
import React, { useEffect } from 'react'

import { FacebookLogin } from '@rdlabo/capacitor-facebook-login'
import firebase from '../utils/firebase'

registerWebPlugin(FacebookLogin)

const FB_PERMS = ['email', 'public_profile']

const Home = ({ history }) => {
  useEffect(() => {
    async function getToken () {
      const { accessToken } = await FacebookLogin.getCurrentAccessToken()

      console.log('token is: ', accessToken)

      if (accessToken) {
        history.push({
          path: '/home'
        })
      }
    }

    getToken()
  }, [history])

  const loginWithFacebook = async () => {
    const { accessToken } = await Plugins.FacebookLogin.login({
      permissions: FB_PERMS
    })
    // const fbCred = firebase.auth.FacebookAuthProvider.credential(accessToken)

    // firebase
    //   .auth()
    //   .signInWithCredential(fbCred)
    //   .then(user => console.log(user))

    history.push('/home')
  }
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Login Screen</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className='ion-padding'>
        The world is your oyster.
        <p>
          If you get lost, the{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://ionicframework.com/docs/'
          >
            docs
          </a>{' '}
          will be your guide.
        </p>
        <IonButton onClick={loginWithFacebook} expand='block'>
          Login with Facebook
        </IonButton>
      </IonContent>
    </IonPage>
  )
}

export default Home
